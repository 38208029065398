import React, { useContext, useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import CssBaseline from "@mui/material/CssBaseline";
import Layout from "components/Layout";
import Routes from "core/Routes";
import { theme } from "uikit/theme";
import { ToastContainer } from "react-toastify";
import ConfigurationContext from "../../contexts/ConfigurationContext";
import AuthContext from "../../contexts/AuthContext";
import ProfileContext from "../../contexts/ProfileContext";
import ScrollWrapper from "../ScrollWrapper";
import TranslationWrapper from "../TranslationWrapper";
import Loader from "../../components/Global/Loader";
import MessagingContext from "contexts/MessagingContext";

import "styles/_main.css";
// import 'plyr-react/plyr.css';
import "react-toastify/dist/ReactToastify.css";
import { PasswordNotSetDialog } from "../PasswordNotSet/PasswordNotSetDialog";

export const App = () => {
  const { configurations, isLoading, isFeatureEnabled, scripts } = useContext(ConfigurationContext);
  const { user } = useContext(AuthContext);
  const { getUnreadCount } = useContext(MessagingContext);
  const { fetchProfile } = useContext(ProfileContext);
  const [scriptsAdded, setScriptsAdded] = React.useState(false);
  const [addedBanners, setAddedBanners] = React.useState(false);
  const [customCss, setCustomCss] = React.useState(false);

  useEffect(() => {
    if (configurations?.programName) document.title = configurations.programName;
    if (configurations.color2) theme.palette.primary.main = configurations.color2;
    if (configurations.color1) theme.palette.secondary.main = configurations.color1;
    if (configurations.color2) theme.palette.secondary.dark = configurations.color2;
    if (configurations.color3) theme.palette.warning.main = configurations.color3;

    if (configurations.custom_css && !customCss) {
      setTimeout(() => {
        const style = document.createElement("style");
        style.innerHTML = configurations.custom_css;
        document.head.appendChild(style);
      }, [500]);
    }

    if (!addedBanners) {
      let banner1 = null;
      let banner2 = null;
      if (configurations.top_banner_link_1_content && configurations.top_banner_link_1_url) {
        banner1 = createTopBanner(
          configurations.top_banner_link_1_content,
          configurations.top_banner_link_1_url
        );
      }
      if (configurations.top_banner_link_2_content && configurations.top_banner_link_2_url) {
        banner2 = createTopBanner(
          configurations.top_banner_link_2_content,
          configurations.top_banner_link_2_url,
          true
        );
      }
      if (banner1 || banner2) {
        let topBanner = document.createElement("div");
        topBanner.classList.add("top-banner-container");
        if (banner1) topBanner.appendChild(banner1);
        if (banner2) topBanner.appendChild(banner2);
        document.body.prepend(topBanner);
        setAddedBanners(true);
      }
    }
  }, [configurations]);

  const createTopBanner = (content, url, second) => {
    //create a div element to hold the banner
    const banner = document.createElement("a");
    banner.href = url;
    banner.classList.add("top-banner");
    banner.target = "_blank";
    banner.style.backgroundColor = configurations.color1;
    if (second) {
      banner.style.backgroundColor = configurations.color2;
    }
    banner.innerHTML = content;
    return banner;
  };

  React.useEffect(() => {
    if (user) {
      if (isFeatureEnabled("family_message")) getUnreadCount();
      fetchProfile(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  React.useEffect(() => {
    const intervalId = setInterval(() => {
      if (isFeatureEnabled("family_message") && user) getUnreadCount();
    }, 15000);
    return () => {
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Chat bot setup
   */
  React.useEffect(() => {
    if (scripts?.length > 0 && !scriptsAdded) {
      setScriptsAdded(true);
      for (let i = 0; i < scripts.length; i++) {
        let scriptBody = scripts[i]?.body;
        const script = document.createElement("script");
        if (/<script[\s\S]*<\/script>/gi.test(scriptBody)) {
          const scriptTagMatches = scriptBody.match(/<script[^>]*>([\s\S]*)<\/script>/i);
          if (scriptTagMatches && scriptTagMatches[1]) {
            script.innerText = scriptTagMatches[1].trim();
          }

          // Check if script has a src attribute within the script tag
          const srcAttributeMatch = scriptBody.match(/<script[^>]*src=["']?([^"'>]+)["']?[^>]*>/i);
          if (srcAttributeMatch && srcAttributeMatch[1]) {
            script.src = srcAttributeMatch[1];
          }
        }
        // Append script to head
        if (scriptBody || script.src) {
          document.head.appendChild(script);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scripts]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <BrowserRouter>
        <ScrollWrapper>
          <ThemeProvider theme={theme}>
            <TranslationWrapper>
              <Layout>
                <CssBaseline />
                <Routes />
                <PasswordNotSetDialog />
              </Layout>
            </TranslationWrapper>
          </ThemeProvider>
        </ScrollWrapper>
      </BrowserRouter>
      <ToastContainer theme="dark" position="top-center" style={{ fontSize: "0.8em" }} />
    </>
  );
};

export default App;
