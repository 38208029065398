import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { COLORS } from "constants/colors";
import { ROUTES } from "constants/routes";
import { familyNetworkAlphabetsData } from "constants";
import apiRequest from "api/apiRequest";
import urls from "api/urls";
import { useEffect } from "react";
import { useState } from "react";
import FamilyCard from "./FamilyNetworkProfileDetails/components/FamilyCard/FamilyCard";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import { Button } from "@mui/material";
import { useFilter, useStateFilter } from "./useFamilyNetworkFilter";

export const FamilyNetworkDetails = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [familyCard, setFamilyCard] = useState([]);
  const [links, setLinks] = useState([]);

  const [{ classFilter, prefix }, setFilter] = useStateFilter("");
  const { query } = useFilter();

  const fetchFamilies = async url => {
    try {
      let operator = url && url.includes("?page") ? "&" : "?";
      setIsLoading(true);

      const response = await apiRequest({
        method: "get",
        url: `${url || urls.families}${operator}${query(prefix, classFilter)}`,
      });
      setFamilyCard(response.data);
      setLinks(response.links);
      setIsLoading(false);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    try {
      fetchFamilies();
    } catch (error) {
      console.log(error);
    }
  }, [prefix, classFilter]);

  const handleClick = item => {
    setFilter(prevState => ({ ...prevState, prefix: item }));
  };

  return (
    <Box mb={10}>
      <Box sx={{ position: "relative" }}>
        <Container>
          <Box sx={{ position: "absolute", top: 10, left: "5%", zIndex: -1 }}>
            <img src={"assets/icons/EllipseFull.svg"} alt="" />
          </Box>
        </Container>
      </Box>
      <Container maxWidth="false" disableGutters>
        <Box justifyContent="center" display="flex">
          <Grid item container md={10} xs={12} justifyContent="center">
            {familyNetworkAlphabetsData.map(item => (
              <Box display="flex" justifyContent="center" alignItems="center">
                <Typography
                  fontSize={16}
                  color="primary"
                  ml={2}
                  mt={{ xs: 2, md: 8 }}
                  onClick={() => handleClick(item)}
                  sx={{ cursor: "pointer" }}
                >
                  {item}
                </Typography>
              </Box>
            ))}
            <Grid>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ paddingTop: "60px", paddingLeft: "60px" }}
              >
                <input
                  type="text"
                  name="classFilter"
                  id="classFilter"
                  placeholder="Search by Class"
                  onChange={e =>
                    setFilter(prevState => ({ ...prevState, classFilter: e.target.value }))
                  }
                  style={{
                    padding: ".5em .8em",
                    borderRadius: "2em",
                    boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
                    border: "none",
                    outline: "none",
                    marginRight: "1em",
                  }}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Container>
          <Box>
            <Typography
              fontSize={{ md: 40 }}
              fontWeight={700}
              color={COLORS.black.dark}
              mt={{ xs: 2 }}
            >
              It Takes a Village
            </Typography>
            <Typography mt={{ xs: 2, md: 3 }} width={{ md: 1045 }} wrap>
              Better connected families improve student experience, academic outcomes and school
              safety. And for parents, it’s always nice to put a face with a name.
            </Typography>
            <Grid item spacing={4} container mt={{ md: 3, xs: 2 }} justifyContent="center">
              {isLoading ? (
                <Grid container justifyContent="center">
                  <LoadingIndicator />
                </Grid>
              ) : (
                familyCard?.map(item => (
                  <Grid item md={4}>
                    <Link to={`${ROUTES.FAMILY_NETWORK_DETAILS}/${item?.id}`}>
                      <FamilyCard
                        image={item?.profile_image?.path}
                        grade={item?.user?.id}
                        userName={item?.last_name}
                        cartoons={item?.user?.bio}
                        id={item?.user?.id}
                        familyMembers={item?.members}
                      />
                    </Link>
                  </Grid>
                ))
              )}
            </Grid>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              sx={{ paddingTop: "60px" }}
            >
              <Grid>
                {links.map(link => (
                  <Button
                    key={link.label}
                    onClick={() => fetchFamilies(link.url)}
                    style={{ fontSize: "20px" }}
                  >
                    <span
                      style={{
                        boxShadow: "rgba(0, 0, 0, 0.1) 0px 0px 10px",
                        borderRadius: "2em",
                        padding: ".5em .8em",
                        display: "block",
                      }}
                      dangerouslySetInnerHTML={{ __html: link.label }}
                    />
                  </Button>
                ))}
              </Grid>
            </Box>
          </Box>
        </Container>
      </Container>
    </Box>
  );
};

export default FamilyNetworkDetails;
