import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import { useTheme } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { logInSchema } from '../../../../validations/schemas';

import { COLORS } from 'constants/colors';
import { CircularProgress } from '@mui/material';
import { ROUTES } from 'constants/routes';

export const AuthSignInForm = (props) => {
  const {
    // OR,
    title,
    subTitle,
    staffAdmin,
    // appleButton,
    // googleButton,
    // facebookButton,
    authButtonText,
    authButtonAction,
    navigationButtonText,
    navigationButtonTextAction,
    authHelperText,
    isLoading,
  } = props;

  const theme = useTheme();
  const {
    register,
    formState: { errors },
    handleSubmit,
    getValues,
  } = useForm({ resolver: zodResolver(logInSchema) });

  const onSubmitHandler = (values) => {
    authButtonAction(values.email, values.password);
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      authButtonAction(getValues('email'), getValues('password'));
    }
  };

  return (
    <React.Fragment>
      <Box textAlign='center' mt={4}>
        <Typography fontWeight={900} fontSize={{ xs: 20, md: 56 }} color={theme.palette.secondary.main}>
          {title}
        </Typography>
        {subTitle && (
          <Typography fontWeight={400} fontSize={{ xs: 20, md: 22 }} color={COLORS.grey.dark}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <Grid px={5} mt={12} spacing={2} container justifyContent={{ xs: 'center', md: 'start' }}>
        <Grid item xs={12}>
          <InputLabel>Email Address</InputLabel>
          <TextField
            fullWidth
            placeholder='Enter Email'
            margin='normal'
            error={!!errors['email']}
            helperText={errors['email'] ? errors['email'].message : ''}
            {...register('email')}
            onKeyPress={handleKeyPress}
          />
        </Grid>
        <Grid item xs={12}>
          <Box display='flex' justifyContent='space-between'>
            <InputLabel>Password</InputLabel>
            <Link to={ROUTES.FORGOT_PASSWORD}>
              <InputLabel sx={{ color: theme.palette.primary.main, cursor: 'pointer' }}>Forgot Password?</InputLabel>
            </Link>
          </Box>
          <TextField
            fullWidth
            type='password'
            placeholder='Enter Password'
            margin='normal'
            error={!!errors['password']}
            helperText={errors['password'] ? errors['password'].message : ''}
            {...register('password')}
            onKeyPress={handleKeyPress}
          />
        </Grid>

        <Grid item md={12} xs={12} textAlign='center' mt={staffAdmin ? 2 : 10}>
          <Button
            size='large'
            variant='contained'
            disabled={isLoading ? true : false}
            sx={{
              p: 2,
              width: 250,
              borderRadius: 50,
              bgcolor: 'secondary.main',
              color: '#fff',
            }}
            onClick={handleSubmit(onSubmitHandler)}
            onKeyDown={(event) => {
              if (event.keyCode === 13) {
                handleSubmit((values) => authButtonAction(values.email, values.password));
              }
            }}
          >
            {isLoading ? <CircularProgress size='1.7em' color='primary' /> : authButtonText}
          </Button>
          {/* {OR && <Typography mt={2}>OR</Typography>}
          <Box display="flex" flexDirection="column" alignItems="center" mt={4}>
            {googleButton && (
              <Button
                variant="contained"
                className="MuiButton-signIn"
                startIcon={<img src="assets/icons/googleIcon.png" alt="" />}
                sx={{
                  mt: 1,
                }}
              >
                Continue with Google
              </Button>
            )}
            {facebookButton && (
              <Button
                variant="contained"
                className="MuiButton-signIn"
                startIcon={
                  <img
                    className="muibuttonfb"
                    src="assets/icons/facebookIcon.png"
                    alt=""
                  />
                }
                sx={{
                  mt: 1,
                }}
              >
                Continue with Facebook
              </Button>
            )}
            {appleButton && (
              <Button
                variant="contained"
                className={"MuiButton-signIn"}
                startIcon={<img src="assets/icons/AppleIcon.png" alt="" />}
                sx={{
                  mt: 1,
                }}
              >
                Continue with Apple
              </Button>
            )}
          </Box> */}
          {navigationButtonText && (
            <Box
              mt={5}
              display='flex'
              alignItems='center'
              justifyContent='center'
              flexDirection={{ xs: 'column', md: 'row' }}
            >
              <Typography fontSize={15} fontWeight={700}>
                {authHelperText ?? 'Have an account already?'}
              </Typography>
              <Button
                variant='text'
                color='primary'
                sx={{ fontSize: 15, fontWeight: 700 }}
                onClick={navigationButtonTextAction}
              >
                {navigationButtonText}
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default AuthSignInForm;
