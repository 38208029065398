import React from "react";
import { ConfigurationProvider } from "../../contexts/ConfigurationContext";
import { ArticlesProvider } from "../../contexts/ArticlesContext";
import { QAProvider } from "../../contexts/QAContext";
import { AmbassadorProvider } from "../../contexts/AmbassadorContext";
import { AuthProvider } from "../../contexts/AuthContext";
import { ProfileProvider } from "../../contexts/ProfileContext";
import { MessagingProvider } from "../../contexts/MessagingContext";
import App from "../App";
import ReactGA from "react-ga";

export const ContextsWrapper = () => {
  React.useEffect(() => {
    ReactGA.initialize("G-T07C4P6J0H");
    ReactGA.pageview(window.location.pathname + window.location.search);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <QAProvider>
      <ConfigurationProvider>
        <ArticlesProvider>
          <AmbassadorProvider>
            <AuthProvider>
              <ProfileProvider>
                <MessagingProvider>
                  <App />
                </MessagingProvider>
              </ProfileProvider>
            </AuthProvider>
          </AmbassadorProvider>
        </ArticlesProvider>
      </ConfigurationProvider>
    </QAProvider>
  );
};

export default ContextsWrapper;
