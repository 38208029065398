import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import AuthContext from "../../../../contexts/AuthContext";
import { CircularProgress } from "@mui/material";
import { SignUpSnackBar } from "../CreateAccount/components/SignUpSnackBar/index";
import { signupSchema } from "../../../../validations/schemas";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import ConfigurationContext from "../../../../contexts/ConfigurationContext";
import { COLORS } from "constants/colors";
import { Roles } from "../../../../utils/Roles";

export const AuthSignUpForm = props => {
  const {
    title,
    subTitle,
    signInForm,
    buttonsArea,
    authButtonText,
    navigationButtonText,
    navigationButtonTextAction,
    authHelperText,
    returnToSignUpAction,
    userRole,
  } = props;

  const [showAlert, setShowAlert] = React.useState(false);
  const [alertMessage, setAlertMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const theme = useTheme();
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const { signUp, isLoading, error } = React.useContext(AuthContext);
  const [verificationCode, setVerificationCode] = React.useState("");
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: zodResolver(signupSchema),
  });

  React.useEffect(() => {
    setShowAlert(false);
  }, []);

  React.useEffect(() => {
    if (error !== "") {
      setAlertType("Failed");
      setAlertMessage(error);
      setShowAlert(true);
    }
  }, [error]);

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const onSubmitHandler = values => {
    const { firstName, lastName, email, password, verification_code, phone_number } = values;
    const newUser = {
      firstName,
      lastName,
      email,
      password,
      verification_code,
      phone_number,
    };
    if (userRole === Roles.alumni) {
      newUser["role_ids"] = [userRole];
    }
    signUp(newUser).then(() => {
      window.location.href = "/";
    });
  };

  return (
    <React.Fragment>
      <Box textAlign="center" mt={4}>
        <Typography
          fontWeight={900}
          fontSize={{ xs: 20, md: 56 }}
          color={theme.palette.secondary.main}
        >
          {title}
        </Typography>
        {buttonsArea && (
          <Typography fontWeight={400} fontSize={{ xs: 20, md: 22 }} color={COLORS.grey.dark}>
            {subTitle}
          </Typography>
        )}
      </Box>
      <Grid
        px={5}
        mt={signInForm ? 15 : 2}
        spacing={2}
        container
        justifyContent={{ xs: "center", md: "start" }}
      >
        <Grid
          item
          md={12}
          sm={12}
          xs={12}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
        >
          <Grid item sm={6} xs={12}>
            <InputLabel>First Name</InputLabel>
            <TextField
              fullWidth
              placeholder="Enter First Name"
              margin="normal"
              error={!!errors["firstName"]}
              helperText={errors["firstName"] ? errors["firstName"].message : ""}
              {...register("firstName")}
            />
          </Grid>
          <Grid item sm={6} xs={12} ml={{ xs: 0, sm: 7 }}>
            <InputLabel>Last Name</InputLabel>
            <TextField
              fullWidth
              placeholder="Enter Last Name"
              margin="normal"
              error={!!errors["lastName"]}
              helperText={errors["lastName"] ? errors["lastName"].message : ""}
              {...register("lastName")}
            />
          </Grid>
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel>Email Address</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter Email"
            margin="normal"
            error={!!errors["email"]}
            helperText={errors["email"] ? errors["email"].message : ""}
            {...register("email")}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel>Phone number</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter phone (optional)"
            margin="normal"
            error={!!errors["phone_number"]}
            helperText={errors["phone_number"] ? errors["phone_number"].message : ""}
            {...register("phone_number")}
          />
        </Grid>

        <Grid item md={12} xs={12}>
          <InputLabel>Password</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter Password"
            margin="normal"
            type="password"
            error={!!errors["password"]}
            helperText={errors["password"] ? errors["password"].message : ""}
            {...register("password")}
          />
        </Grid>
        <Grid item md={12} xs={12}>
          <InputLabel>Confirm Password</InputLabel>
          <TextField
            fullWidth
            placeholder="Enter Confirm Password"
            margin="normal"
            type="password"
            error={!!errors["confirmPassword"]}
            helperText={errors["confirmPassword"] ? errors["confirmPassword"].message : ""}
            {...register("confirmPassword")}
          />
        </Grid>
        {isFeatureEnabled("program_verification_code") && userRole !== 2 ? (
          <Grid item md={12} xs={12}>
            <InputLabel>Verification Code</InputLabel>
            <TextField
              fullWidth
              onChange={e => setVerificationCode(e.target.value)}
              value={verificationCode}
              placeholder="Enter Verification Code"
              margin="normal"
              error={!!errors["verificationCode"]}
              helperText={errors["verificationCode"] ? errors["verificationCode"].message : ""}
            />
          </Grid>
        ) : null}
        <Grid item md={12} xs={12} textAlign="center" mt={signInForm ? 10 : 3}>
          <Button
            variant="contained"
            size="large"
            sx={{
              width: 250,
              borderRadius: 50,
              bgcolor: "secondary.main",
              color: "#fff",
            }}
            onClick={handleSubmit(onSubmitHandler)}
          >
            {isLoading ? <CircularProgress size="1.7em" color="primary" /> : authButtonText}
          </Button>
          {/* <Typography mt={2}>OR</Typography>
          {!buttonsArea ? (
            <Box display='flex' flexDirection='column' alignItems='center' mt={signInForm ? 4 : 0}>
              <Button
                className={buttonVariant ? 'MuiButton-signIn' : 'MuiButton-signUp'}
                variant={buttonVariant || 'outlined'}
                startIcon={<img src='assets/icons/googleIcon.png' alt='' />}
                sx={{
                  mt: 1,
                }}
              >
                Continue with Google
              </Button>
              <Button
                variant={buttonVariant || 'outlined'}
                className={buttonVariant ? 'MuiButton-signIn' : 'MuiButton-signUp '}
                startIcon={<img className='muibuttonfb' src='assets/icons/facebookIcon.png' alt='' />}
                sx={{
                  mt: 1,
                }}
              >
                Continue with Facebook
              </Button>
              <Button
                variant={buttonVariant || 'outlined'}
                className={buttonVariant ? 'MuiButton-signIn' : 'MuiButton-signUp'}
                startIcon={<img src='assets/icons/AppleIcon.png' alt='' />}
                sx={{
                  mt: 1,
                }}
              >
                Continue with Apple
              </Button>
            </Box>
          ) : null} */}
          <Box
            mt={signInForm ? 5 : 2}
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection={{ xs: "column", md: "row" }}
          >
            <Typography fontSize={15} fontWeight={700}>
              {authHelperText ?? "Have an account already?"}
            </Typography>
            <Button
              variant="text"
              color="primary"
              sx={{ fontSize: 15, fontWeight: 700 }}
              onClick={navigationButtonTextAction}
            >
              {navigationButtonText}
            </Button>
          </Box>
          <Button
            variant="text"
            color="primary"
            sx={{ fontSize: 15, fontWeight: 700 }}
            onClick={returnToSignUpAction}
          >
            RETURN TO SIGNUP
          </Button>
        </Grid>
      </Grid>
      {showAlert && (
        <SignUpSnackBar
          familyLogin
          width={{ xs: 250, sm: 600, md: "1200px" }}
          open={showAlert}
          close={handleCloseAlert}
          status={alertType}
          message={alertMessage}
          borderColor={theme.palette.primary.main}
        />
      )}
    </React.Fragment>
  );
};

export default AuthSignUpForm;
