import React, { useEffect, useState, useRef } from "react";
import ClubPage from "./components/ClubPage";
import { Heading } from "./components/Heading";
import Container from "@mui/material/Container";
import { CardSection } from "./components/CardSection";
import apiRequest from "../../api/apiRequest";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import ClearIcon from "@mui/icons-material/Clear";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { BackgroundCircle } from "components/Global/BackgroundCircle";
import { useTheme } from "@mui/material/styles";

const CATEGORY_ICONS = {
  Community: "/assets/icons/community.svg",
  Electives: "/assets/icons/electives.png",
  Extracurriculars: "/assets/icons/art.svg",
  "Family Groups": "/assets/icons/family_groups.svg",
  Sports: "/assets/icons/sports.svg",
  "Teacher Groups": "/assets/icons/teacher_groups.svg",
  "The Arts": "/assets/icons/arts.png",
  "Academic Programs": "/assets/icons/academics.png",
};

export const ManageClubHub = () => {
  const [clubs, setClubs] = React.useState([]);
  const [clubCategories, setClubCategories] = React.useState({});
  const [searchQuery, setSearchQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [showNoResultsMessage, setShowNoResultsMessage] = useState(false);
  const theme = useTheme();
  const fromBack = sessionStorage.getItem("backButtonClick");
  const lastClicked = fromBack === "true" ? sessionStorage.getItem("lastClicked") : null;

  const lastCatClick = acc => {
    sessionStorage.setItem("lastClicked", acc);
  };

  const handleScroll = () => {
    const position = window.scrollY;
    sessionStorage.setItem("scrollPosition", position);
    const scrollPosition = sessionStorage.getItem("scrollPosition");
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollPosition = () => {
    const alreadyClicked = sessionStorage.getItem("accordClicked");
    const scrollPosition = fromBack === "true" ? sessionStorage.getItem("scrollPosition") : 0;
    if (scrollPosition) {
      sessionStorage.setItem("backButtonClick", null);
      window.scrollTo(0, scrollPosition);
    }
  };

  // Search functionality
  const handleSearch = e => {
    const results = clubs.filter(club =>
      club.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setSearchResults(results);

    if (results.length === 0) {
      setShowNoResultsMessage(true);
    } else {
      setShowNoResultsMessage(false);
    }
  };

  // Clear search
  const clearSearch = () => {
    setSearchQuery("");
    setSearchResults([]);
    setShowNoResultsMessage(false);
  };

  const loadClubs = () => {
    apiRequest({
      url: `/clubs?active=1`,
      method: "GET",
    }).then(
      res => {
        let c = [];
        let categories = {};
        res.data.forEach(club => {
          c.push(club);
          const categoryName = club.categories[club.category];
          if (categories[categoryName]) {
            categories[categoryName].push(club);
          } else {
            categories[categoryName] = [club];
          }
        });
        setClubs(c);
        setClubCategories(categories);
      },
      e => {
        console.log(e);
      }
    );
  };

  const mounted = useRef();
  useEffect(() => {
    if (!mounted.current) {
      loadClubs();
      mounted.current = true;
    } else {
      handleScrollPosition();
    }
  });

  return (
    <React.Fragment>
      <Container maxWidth="xl" style={{ padding: "0 10%", position: "relative" }}>
        <Heading />
        <div style={{ position: "absolute", left: "90%", top: "-80px" }}>
          <BackgroundCircle
            zIndex="-1"
            size={120}
            color={theme.palette.primary.main}
            strokeWidth={30}
          />
        </div>
        <Box
          mt={{ md: 10, sm: 5, xs: 5 }}
          sx={{
            p: 2,
            mb: 5,
            width: "auto",
            bgcolor: "white",
            alignItems: "center",
            borderRadius: "26px",
            display: { lg: "flex", md: "flex", sm: "flex", xs: "flex" },
            boxShadow: "0px 4px 10px 7px rgba(0, 0, 0, 0.05)",
          }}
        >
          <InputBase
            sx={{ flex: 1, paddingLeft: "1em" }}
            placeholder="Search by organization name"
            inputProps={{ "aria-label": "search" }}
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            onKeyUp={handleSearch}
          />
          {searchResults.length > 0 && (
            <IconButton onClick={clearSearch}>
              <ClearIcon />
            </IconButton>
          )}
        </Box>
      </Container>

      <Container maxWidth="xl" style={{ padding: "0 10%" }}>
        {searchQuery && searchResults.length > 0 ? (
          <Grid container spacing={2}>
            {searchResults
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(club => (
                <CardSection club={club} />
              ))}
          </Grid>
        ) : null}

        {searchQuery && showNoResultsMessage ? (
          <Typography>No results match your search</Typography>
        ) : null}

        <div style={{ position: "absolute", left: "-40px" }}>
          <BackgroundCircle zIndex="-1" color={theme.palette.primary.main} strokeWidth={30} />
        </div>
        {Object.keys(clubCategories)
          .sort()
          .map(category => (
            <Accordion
              defaultExpanded={category === lastClicked ? true : false}
              style={{
                boxShadow: "none",
                backgroundColor: "transparent",
                borderBottom: "1px solid black",
                paddingBottom: ".5em",
                paddingTop: "2em",
              }}
              onChange={() => lastCatClick(category)}
            >
              <AccordionSummary
                expandIcon={
                  <div style={{ transform: "scale(2.5)" }}>
                    <ExpandMoreIcon />
                  </div>
                }
                aria-controls={`panel-content-${category}`}
                id={`panel-header-${category}`}
              >
                <img
                  src={CATEGORY_ICONS[category]}
                  alt={category.clubName}
                  style={{ height: "50px", marginRight: "1em" }}
                />
                <Typography
                  gutterBottom
                  variant="h6"
                  sx={{
                    color: "#131416",
                    fontWeight: "900",
                    lineHeight: "58px",
                    fontSize: "40px",
                    fontStyle: "normal",
                    "@media (max-width: 800px)": {
                      fontSize: "20px",
                    },
                  }}
                >
                  {category}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={2} style={{ flexDirection: "row" }}>
                  {clubCategories[category]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(club => (
                      <CardSection club={club} />
                    ))}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
      </Container>

      <Container maxWidth="xl" sx={{ height: "100px" }}></Container>
      <div style={{ position: "relative" }}>
        <div style={{ position: "absolute", right: "280px", bottom: "310px" }}>
          <BackgroundCircle
            zIndex="-1"
            size={300}
            color={theme.palette.primary.main}
            strokeWidth={30}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export const ManageClubPage = ClubPage;

export default ManageClubHub;
