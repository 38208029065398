import React, { useEffect } from "react";
import AddStaffCard from "../AddStaffCard";
import Box from "@mui/system/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import AddStaffModal from "../AddStaffModal";
import DeleteModal from "../DeleteModal";
import urls from "api/urls";
import { LeaderCard } from "../../../../../../ManageClubHub/components/LeadersContact/LeaderCard";

const AddStaff = props => {
  const [leaders, setLeaders] = React.useState(props.leaders || []);
  useEffect(() => {
    setLeaders(props.leaders || []);
  }, [props.leaders]);

  useEffect(() => {
    if (props.onChange) {
      props.onChange(leaders);
    }
  }, [leaders]);

  const [isShowStaffModal, setIsShowStaffModal] = React.useState(false);
  const [deleteStaffId, setDeleteStaffId] = React.useState(null);

  const [isDeleteModal, setIsDeleteModal] = React.useState(false);

  const removeLeader = id => {
    setDeleteStaffId(id);
    setIsDeleteModal(true);
  };

  return (
    <React.Fragment>
      <Container maxWidth="xl">
        <Box my={5}>
          <Typography variant="h4">Contact us to learn more</Typography>
        </Box>

        <Grid container spacing={3}>
          <Grid item md={4} sm={6} xs={12}>
            {leaders.length ? (
              <LeaderCard onRemove={leader => removeLeader(leader.id)} leader={leaders[0]} />
            ) : (
              <AddStaffCard height={"385px"} onOpenModal={() => setIsShowStaffModal(true)} />
            )}
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            {leaders.length >= 2 ? (
              <LeaderCard onRemove={leader => removeLeader(leader.id)} leader={leaders[1]} />
            ) : (
              <AddStaffCard height={"385px"} onOpenModal={() => setIsShowStaffModal(true)} />
            )}
          </Grid>

          <Grid item md={4} sm={6} xs={12}>
            {leaders.length >= 3 ? (
              <LeaderCard onRemove={leader => removeLeader(leader.id)} leader={leaders[2]} />
            ) : (
              <AddStaffCard height={"385px"} onOpenModal={() => setIsShowStaffModal(true)} />
            )}
          </Grid>
        </Grid>
      </Container>
      <AddStaffModal
        onConfirm={staff => {
          setIsShowStaffModal(false);
          setLeaders([...leaders, staff]);
        }}
        setIsShowStaffModal={setIsShowStaffModal}
        isShowStaffModal={isShowStaffModal}
      />
      <DeleteModal
        title="Delete Club Leader"
        text="Are you sure you want to delete?"
        setIsDeleteModal={setIsDeleteModal}
        isDeleteModal={isDeleteModal}
        onConfirm={() => {
          setIsDeleteModal(false);
          setLeaders(leaders.filter(staff => staff.id !== deleteStaffId));
        }}
      />
    </React.Fragment>
  );
};

export default AddStaff;
