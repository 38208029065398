import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { ROUTES } from "constants/routes";
import { ManageQA } from "pages/ManageQA";
import { ManagePrivacyPolicy } from "pages/ManagePrivacyPolicy";
import { ManageTermsOfUse } from "pages/ManageTermsOfUse";
import { ManageAuth } from "pages/ManageAuth";
import { ManageHome } from "pages/ManageHome";
import { ManageTips } from "pages/ManageTips";
import { ManageClubHub } from "pages/ManageClubHub";
import { ManageClubPage } from "pages/ManageClubHub";
import { ManageOutcomes } from "pages/ManageOutcomes";
import { MyAccount } from "pages/MyAccount";
import { ManageStaff } from "pages/ManageStaffDirectory";
import { ManageGraduates } from "pages/ManageGraduates/ManageGraduates";
import { ManageFamilyNetwork } from "pages/ManageFamilyNetwork";
import { ManageStaffProfile } from "pages/ManageStaffDirectory";
import { ManageStaffDirectory } from "pages/ManageStaffDirectory";
import { StaffDirectoryDetails } from "pages/ManageStaffDirectory/StaffDirectoryDetails";
import { GraduateDetails } from "pages/ManageGraduates/GraduateDetails";
import { ClubsEditor } from "pages/ManageProfiles/ClubProfile/components";
import { ManageFamilyAmbassadorsDetails } from "pages/ManageFamilyNetwork";
import { ManageFamilyAmbassadorrsProfile } from "pages/ManageFamilyNetwork";
import { AuthLandingScreen } from "pages/ManageAuth/components/AuthLandingScreen";
import ArticleDetail from "pages/ManageTips/components/ArticleDetail";
import { PrivateRoute } from "components/Layout/PrivateRoute";
import apiRequest from "./../../api/apiRequest";
import { useLocation } from "react-router-dom";
import FamilyProfile from "pages/FamiliesProfile";
import { StaffProfile } from "pages/StaffProfile";
import { GraduateProfile } from "pages/GraduateProfile";
import FamilyNetworkProfileDetails from "pages/FamiliesNetwork/FamilyNetworkProfileDetails/FamiliesNetworkProfileDetails";
import FamilyNetworkDetails from "pages/FamiliesNetwork/FamilyNetwork";
import { CustomPage } from "../../pages/CustomPages/CustomPage";
import { AlumniProfile } from "../../pages/AlumniProfile/AlumniProfile";
import { AlumniDetails } from "../../pages/AlumniProfileDetails/AlumniDetails";
import { AlumniProfileUpdate } from "../../pages/AlumniprofileUpdate/AlumniProfileUpdate";
import { EmailAuthentication } from "../../pages/ManageAuth/components/LoginAccount/EmailAuthentication";

let lastLocation = "";
const TrackPageviews = function () {
  let session = localStorage.getItem("session");
  if (!session) {
    //Create a random session ID
    let chars = "0123456789ABCDEFGHIJKLMNOPQRSTUVWXTZabcdefghiklmnopqrstuvwxyz".split("");
    let str = "";
    while (str.length < 64) {
      str += chars[Math.floor(Math.random() * chars.length)];
    }
    session = str;
    localStorage.setItem("session", session);
  }
  let location = useLocation();
  useEffect(() => {
    if (location.pathname !== lastLocation) {
      lastLocation = location.pathname;
      apiRequest({
        url: "/reporting/events",
        method: "post",
        data: {
          event: "pageview",
          uri: location.pathname,
          admin: false,
          session: localStorage.getItem("session"),
        },
      });
    }
  }, [location]);
};

export const Routes = () => {
  TrackPageviews();
  return (
    <Switch>
      {/* Public Routes */}
      <Route exact path={ROUTES.HOME} component={ManageHome} />
      <Route exact path={ROUTES.TIPS} component={ManageTips} />
      <Route exact path={ROUTES.TIPS_ARTICLE} component={ArticleDetail} />
      <Route exact path={ROUTES.QA} component={ManageQA} />
      <Route exact path={ROUTES.QA + "/:id"} component={ManageQA} />
      <Route exact path={ROUTES.OUTCOMES} component={ManageOutcomes} />
      <Route exact path={ROUTES.FAMILY_NETWORK} component={ManageFamilyNetwork} />
      <Route exact path={ROUTES.FAMILY_NETWORK_DETAILS} component={FamilyNetworkDetails} />
      <Route exact path={ROUTES.STAFF_DIRECTORY} component={ManageStaffDirectory} />
      <Route exact path={ROUTES.STAFF_DIRECTORY_DETAILS} component={StaffDirectoryDetails} />
      <PrivateRoute exact path={ROUTES.ALUMNI_NETWORK} component={AlumniProfile} />
      <PrivateRoute exact path={ROUTES.GRADUATES} component={ManageGraduates} />
      <PrivateRoute exact path={ROUTES.GRADUATE_DETAILS} component={GraduateDetails} />
      <PrivateRoute exact path={ROUTES.ALUMNI_NETWORK_DETAILS} component={AlumniDetails} />

      <PrivateRoute exact path={ROUTES.FAMILY_PROFILE} component={FamilyProfile} />
      <PrivateRoute exact path={ROUTES.STAFF_PROFILE} component={StaffProfile} />
      <PrivateRoute exact path={ROUTES.GRADUATE_PROFILE} component={GraduateProfile} />
      <PrivateRoute exact path={ROUTES.ALUMNI_PROFILE} component={AlumniProfileUpdate} />
      <PrivateRoute exact path={ROUTES.MY_ACCOUNT} component={MyAccount} />

      <Route
        exact
        path={ROUTES.FAMILY_NETWORK_DETAILS_INDIVIDUAL}
        component={FamilyNetworkProfileDetails}
      />
      <Route exact path={ROUTES.OUR_STAFF} component={ManageStaff} />
      <Route exact path={ROUTES.CLUB_HUB} component={ManageClubHub} />
      <Route exact path={ROUTES.PROFILES} component={FamilyProfile} />
      <Route exact path={ROUTES.CLUB_PAGE} component={ManageClubPage} />
      <Route exact path={ROUTES.STAFF_PROFILE} component={ManageStaffProfile} />
      <Route exact path={ROUTES.CLUB_EDIT} component={ClubsEditor} />
      <Route exact path={ROUTES.CUSTOM_PAGE} component={CustomPage} />

      {/* <Route exact path={ROUTES.STAFF_PROFILE} component={ManageStaffProfile} /> */}
      <Route exact path={ROUTES.CLUB_EDITOR} component={ClubsEditor} />

      <Route exact path={ROUTES.STAFF_PROFILE} component={ManageStaffProfile} />
      <Route exact path={ROUTES.CLUB_EDIT} component={ClubsEditor} />
      <Route exact path={ROUTES.CLUB_EDITOR} component={ClubsEditor} />

      <Route exact path={ROUTES.PRIVACY_POLICY} component={ManagePrivacyPolicy} />
      <Route exact path={ROUTES.TERMS_OF_USE} component={ManageTermsOfUse} />

      <Route
        exact
        path={ROUTES.FAMILY_AMBASSADORS_DETAILS}
        component={ManageFamilyAmbassadorsDetails}
      />
      <Route
        exact
        path={ROUTES.FAMILY_AMBASSADORS_PROFILE_DETAILS}
        component={ManageFamilyAmbassadorrsProfile}
      />
      <Route exact path={ROUTES.PROFILE_DETAILS} component={ManageFamilyAmbassadorrsProfile} />
      <Route exact path={ROUTES.AUTH_LANDING_SCREEN} component={AuthLandingScreen} />
      <Route exact path={ROUTES.EMAIL_TOKEN_AUTOLOGIN} component={EmailAuthentication} />
      <Route exact path={ROUTES.SIGN_IN} component={ManageAuth} />
      <Route exact path={ROUTES.SIGN_UP} component={ManageAuth} />
      <Route exact path={ROUTES.FORGOT_PASSWORD} component={ManageAuth} />
      <Route exact path={ROUTES.RESET_PASSWORD} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_GRADUATE_SIGNIN} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_GRADUATE_SIGNUP} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_CLUB_HUB_SIGNIN} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_CLUB_HUB_SIGNUP} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_ADMIN_STAFF_SIGNIN} component={ManageAuth} />
      <Route exact path={ROUTES.AUTH_ADMIN_STAFF_SIGNUP} component={ManageAuth} />
    </Switch>
  );
};

export default Routes;
